<template>
  <div class="background">
    <div>
      <h1>
        Website Administration Utilities
      </h1>

      <ul>
        <li><router-link to="/admin/user/list">User Dashboard</router-link></li>
        <li>Add Organisation</li>
        <li>Edit Dataset Information (not data)</li>
        <li>View Website Usage Statistics</li>
        <li>Edit Another User</li>
      </ul>
    </div>

  </div>
</template>

<script>
import API_URL from "@/services/url.js";

export default {
  name: 'AdminHome',
  components: {},
  data() {
    return {
      content: '',
      API_URL
    };
  },
  mounted() {
    if (!this.$store.state.auth.status.loggedIn ) {
      this.$router.push('/auth/login')
    }
  }
};
</script>

<style scoped>

.background {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;
}

</style>
